.profil-page-container {
  width: 100%;
  height: 80%;
  background-color: #28272c;
  border-radius: 10px;
  filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.profil-page-header {
  background-color: #3f4043;
  border-radius: 10px;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  height: 7%;
  width: 100%;
  justify-content: flex-start;
}

.profil-page-main-container {
  height: 93%;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding-inline: 2.5%;
  padding-block: 1%;
}

.profil-page-infos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
  margin-bottom: 5%;
  width: 100%;
  justify-content: space-around;
}

.profil-page-password {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  transform: translate(0%, 10%);
  text-align: end;
}

.profil-page-info {
  display: flex;
  flex-direction: row;
  height: 30%;
  width: 100%;
  gap: 5%;
  align-items: center;
  justify-content: center;
}

.profil-page-picture-container {
  background-color: #1f1e22;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
}

.profil-page-title-container {
  display: flex;
  flex-direction: row;
  width: 25%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 10%;
}

.profil-page-notif-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 30%;
}

.profil-page-notif-mail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3%;
  width: 100%;
  height: 25%;
}

.profil-page-notif-download {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 25%;
  gap: 3%;
}

.profil-page-footer {
  place-self: end;
  width: 25%;
  height: 15%;
  margin-top: 5%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  border-radius: 10px;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  border-radius: 5px;
  width: 2rem;
  padding: 1%;
  text-align: end;
}

input[type="number"]::-webkit-textfield-decoration-container {
  appearance: none;
  color: #fff;
  font-family: "Fira Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

input[type="checkbox"] {
  appearance: none;
  background-color: #1A191E;
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #1A191E;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.8rem;
  height: 1rem;
  border-radius: 5px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: rgb(26, 25, 30);
}

input[type="checkbox"]:checked::before {
  clip-path: polygon(14% 55%, 0 55%, 50% 85%, 85% 30%, 95% 10%, 43% 62%);
  font-size: 0.8rem;
  background-color: white;
  transform: scale(1);
}

.checkbox {
  background-color: #1A191E;
  width: 1vw;
}
