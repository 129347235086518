.big-rounded-button-main-container {
    width: 100%;
    height: 60%;
    background-color: var(--blue);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 1px 3px 2px rgb(0 0 0 / 42%);
    cursor: pointer;
    transition: 150ms ease-out;
    font-weight: 600 !important;
    overflow: hidden;
    text-decoration: underline;
    box-sizing: border-box;
    font-size: 16px !important;
}

.big-rounded-button-main-container:hover {
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
}

.big-rounded-button-text {
    font-size: 0.8vw
}

@media screen and (max-width: 950px) {
    .big-rounded-button-main-container {
        font-size: 12px !important;
    }
    .big-rounded-button-text {
        margin-left: 0;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .big-rounded-button-main-container {
        height: 65%;
    }
}