.sep-tool-outputs-list-border-container {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
} 

.sep-tool-outputs-list-main-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.4vw;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 0.5vw;
    object-fit: cover;
    margin-top: 0.35vw;
}

.sep-list-outputs-input-overlay {
    display: flex;
    height: 74.6%;
    width: 82.55%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    right: 0;
    overflow-x: scroll;
    margin-top: -0.3vw;
}

.progress-cursor-input-bar-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
}

.sep-tool-progress-timeline {
    width: 82.5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    right: 0;
    margin-top: 0.57vw;
}

.sep-tool-progress-timeline-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    overflow-x: hidden;
    padding-bottom: 0.63vw;
}

.sep-tool-progress-timestamps-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    overflow-x: hidden;
    font-size: 0.5vw;
    font-weight: 600;
    opacity: 0.2;
}

.progress-timeline-cursor-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    overflow-x: hidden;
    margin-left: -0.5vw;
}