.sep-tool-main-drag-and-drop-container-border-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0.4vw;
}

.sep-tool-drag-and-drop-container-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--special-black);
    border-radius: 0.5vw;
    opacity: 0.86;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 0.4vw;
    padding: 1vw;
}

.sep-tool-upload-audio-dd-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sep-tool-upload-in-progress-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}