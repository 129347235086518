.share-modal-container {
  position: absolute;
  height: 45%;
  width: 30%;
  background-color: #2a2930;
  color: white;
  top: 50%;
  left: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 1%;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}
.fade-out {
  transition: opacity 0.5s ease; /* Adjust duration and timing function as needed */
}

.hidden {
  opacity: 0;
}

.sent-mail-modal {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 101;
  height: 10%;
  width: 20%;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #2a2930;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5%;
  border-radius: 10px;
  padding: 1%;
}

.share-modal-button-container {
  height: 100%;
  width: 7%;
  border-radius: 50px;
  border: white 1px solid;
  padding: 1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 15%;
  width: 100%;
  gap: 2%;
}

.share-modal-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 60%;
  width: 80%;
  text-align: start;
  overflow-wrap: anywhere;
}

.share-modal-mail-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
  align-items: center;
  gap: 5%;
}

.share-modal-mail {
  background-color: #3f4043;
  border-radius: 10px;
  padding-block: 1.5%;
  padding-inline: 4%;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.share-modal-footer {
  place-self: flex-end;
  align-items: center;
  width: 30%;
  height: 20%;
  display: flex;
}
