.App {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  /* background-color: var(--black); */
  background: url("assets/backgrounds/home-bg.svg") var(--black);
}

.disable-text-selection {
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}
