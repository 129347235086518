.source-sep-tool-border-container {
    height: 100%;
    width: 80%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.source-sep-tool-main-container {
    /* width: 100%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--special-black);
    border-radius: 0.5vw;
    position: relative;
    flex-direction: row;
    padding: 0.7vw 1vw 0.7vw 1vw;
    box-sizing: border-box;
}

.source-sep-tool-song-model-selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.source-sep-tool-song-selection-title {
    width: 5vw;
    font-weight: 900;
    font-size: 0.7vw;
}

.source-sep-tool-model-selector-container {
    background-color: #1f1e22;
    border-radius: 10px;
    color: white;
    width: 10vw;
    height: 4.2vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.source-sep-tool-input-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
}

.source-sep-tool-model-selector-container:hover {
    cursor: pointer;
}

.source-sep-tool-player-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.source-sep-tool-right-player-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5%;
    height: 100%;
    width: 5.9vw;
}

.source-sep-tool-right-button-container {
    background-color: #3f4043;
    border-radius: 50px;
    display: flex;
    height: 3.5vh;
    width: 30%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.source-sep-tool-right-timer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 18vw;
    gap: 5%;
}

.source-sep-tool-right-volume-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
    height: 100%;
    width: 7.7vw;
}
