@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-grey: #28272c;
  --dark-grey-bis: #36353A;
  --black: #1A191E;
  --dark-black: #141415;
  --light-grey: #3F4043;
  --shiny-grey: #757575;
  --blue: #3B3650;
  --purple: #5A46D2;
  --grayPurple: #3B3650;
  --darkPurple: #45369F;
  --shiny-purple: #7762CB;
  --dark-pink: #722940;
  --orange: #FF9473;
  --light-pink: #FF6D97;
  --light-yellow: #EDD039;
  --dark-yellow: #DB9C3B;
  --navy-blue: #197FD2;
  --lightGreen: #008F74;
  --red: #A61932;
  --orangeBrown: #CA5022;
  --pink: #CA4872;
  --salmon: #CF8977;
  --lowIntenseOrange : #F5A623;
  --yellow: #D3A958;
  --darkSalmon: #B96365;
  --light-blue: #85A4D6;
  --dark-blue: #3A4E7A;
  --special-black: #1f1e22;
}