.helper-text-container-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36rem;
    height: auto;
    border-radius: 10px;
    background-color: var(--blue);
    position: absolute;
    top: 89%;
    right: 1rem;
    z-index: 7;
    color: white;
    box-sizing: border-box;
    padding: 1%;
    text-align: start;
}

@media  screen and (max-width: 950px) {
    .helper-text-container-main-container{
        width: 100%;
        right: 0;
        font-size: 9px !important;
    }
}