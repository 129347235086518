.source-sep-library-right-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  height: 14%;
  position: relative;
}

.source-sep-library-right-player-container {
  width: 60%;
  height: 100%;
  background-color: #1f1e22;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 1%;
  justify-content: space-between;
}

.source-sep-library-right-player-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5%;
  height: 100%;
  width: 15%;
}

/* Audio progress bar */

input[type="range"].audio-progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none; /*nécessaire pour Chrome */
  padding: 0; /* nécessaire pour IE */
  font: inherit; /* même rendu suivant font document */
  outline: none;
  color: #000000; /* sert pour couleur de référence, via currentColor, pour le curseur */
  background: #ffffff; /* sert pour couleur de fond de la zone de déplacement */
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box; /* même modèle de boîte pour tous */
  transition: opacity 0.2s;
  cursor: pointer;
  height: 2px;
  width: 100%;
  border-radius: 10px;
}

/* Chrome, Safari, Edge, Opera */
/* la zone de déplacement */
input[type="range"].audio-progress-bar::-webkit-slider-runnable-track {
  height: 100%;
  border: none;
  border-radius: 20%;
  background-color: transparent; /* supprimé définie sur l'input */
}

/* le curseur */
input[type="range"].audio-progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none; /* également nécessaire sur le curseur */
  height: 12px; /* s'adapte à la hauteur de l'input */
  width: 12px;
  border: none;
  border-radius: 50%; /* pris en compte sur Webkit et Edge */
  background: white; /* pris en compte sur Webkit only */
}

/* Firefox */
/* la zone de déplacement */
input[type="range"].audio-progress-bar::-moz-range-track {
  height: 100%;
  border: none;
  border-radius: 20%;
  background-color: transparent; /* supprimé définie sur l'input */
}

/* le curseur */
input[type="range"].audio-progress-bar::-moz-range-thumb {
  -moz-appearance: none; /* également nécessaire sur le curseur */
  height: 12px; /* s'adapte à la hauteur de l'input */
  width: 12px;
  border: none;
  border-radius: 50%; /* pris en compte sur Webkit et Edge */
  background: white; /* pris en compte sur Webkit only */
}
/* */

.source-sep-library-right-button-container {
  background-color: #3f4043;
  border-radius: 50px;
  display: flex;
  height: 3.5vh;
  width: 30%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.source-sep-library-right-timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  gap: 5%;
}

.source-sep-library-right-volume-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;
  height: 100%;
  width: 20%;
}

.source-sep-library-right-model-container {
  background-color: #1f1e22;
  border-radius: 10px;
  padding: 1%;
  color: white;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.source-sep-library-right-model {
  background-color: #1a191e;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 5%;
  cursor: pointer;
}

.source-sep-library-right-split-all-container {
  width: 24%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #1f1e22;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  padding: 1%;
}
