.login-page-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 5%;
    padding: 1.2vw;
}

.login-page-box-container {
    height: 100%;
    width: 43%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4vw;
    padding: 4vw 2% 2% 2%;
    border-radius: 0.4vw;
    background: linear-gradient(180deg, #28272C 0%, rgba(26, 25, 30, 0.72) 100%);
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.login-page-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.6vw;
    width: 100%;
}

.login-page-title-description {
    font-size: 0.75vw;
    font-style: italic;
    font-weight: 400;
}

.login-page-title {
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
}

.login-submit-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.3vw;
}

.google-oauth-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 0.3vw;
    padding: 0.5vw 0.8vw;
    border-radius: 1.2vw;
    background: var(--light-blue);
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 0.5vw;
    font-weight: 600;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s ease;
}

.google-oauth-button:hover {
    background: var(--dark-blue);
}

.login-page-login-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 0.55vw;
    font-weight: 250;
    transition: all 0.3s ease;
}

.alternative-login-methods {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.6vw;
    width: 100%;
    margin-top: 1.3vw;
    margin-bottom: 1.3vw;
    color: white;
}

.login-page-forgot-password-link {
    display: flex;
    justify-content: flex-end;
    padding-right: 1.3vw;
    margin-top: 0.1vw;
    font-size: 0.55vw;
    font-weight: 250;
    transition: all 0.3s ease;
}