.separation-tool-main-container {
    height: 88.5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.4vw;
    filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, 0.25));
}

.separation-tool-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-grey);
    border-radius: 0.4vw;
    object-fit: cover;
}

.separation-tool-main-drag-drop-overlay-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    margin-top: 2vw;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 0%;
    border-radius: 0.4vw;
}

.separation-tool-content-time-line-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 0.5vw;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.separation-tool-footer-download-all-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.separation-tool-footer-main-container {
    display: flex;
    width: 100%;
    height: 8%;
    justify-content: space-between;
    align-items: center;
}

.separation-tool-footer-scroller-container {
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 1vw;
}

.source-sep-footer-download-all-button-container {
    height: 70%;
    width: 6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-grey);
    padding-left: 0.6rem;
    border-radius: 29.5px;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    gap: 0.3rem;
    cursor: pointer;
    margin-right: 1vw;
}

.separation-tool-footer-download-all-container:hover {
    background-color: var(--dark-grey);
    cursor: pointer;
}
  
.source-sep-footer-download-all-button-title {
    height: 100%;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6vw;
    font-weight: 200;
    color: var(--white);
}

.source-sep-footer-download-all-button-icon {
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-grey);
    box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
}
