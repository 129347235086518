.separation-tool-header-container {
    display: flex;
    width: 100%;
    height: 6%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light-grey);
    border-radius: 0.4vw;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;

}

.separation-tool-header-title {
    font-size: 0.7vw;
    font-weight: 700;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--purple);
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    padding-left: 0.8vw;
    padding-right: 0.8vw;
    border-radius: 0.4vw;
}

.separation-tool-header-tools {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-right: 0.8vw;
    gap: 0.8vw;
    margin-right: 0.3vw;
}

.separation-tool-header-fold {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3vw 0.4vw 0.35vw 0.4vw;
    background-color: var(--dark-grey-bis);
    border-radius: 10vw;
}

.separation-tool-header-fold:hover {
    background-color: var(--dark-grey);
    cursor: pointer;
}
