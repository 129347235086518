.mvp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    height: 80%;
    width: 100%;
    flex-direction: column;
    background-color: #28272C;
}

.mvp-dropdown {
    height: 10%;
    width: 10%;
}