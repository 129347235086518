.light-text {
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 300;
}

.generic-button {
  background-color: var(--purple);
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px #1a191e;
}

.shadow {
  box-shadow: 0px 2px 4px 0px #1a191e;
}

.underline {
  min-height: 1px;
  width: 100%;
  background-color: #48484d;
}

.generic-button-no-hover {
  background-color: var(--purple);
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px #1a191e;
  overflow-wrap: anywere;
}

.generic-button:hover {
  cursor: pointer;
  background-color: var(--darkPurple);
}

.generic-small-bold-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 600;
}

.generic-small-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
}

.generic-medium-bold-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 600;
}

.generic-medium-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
}

.generic-big-bold-text {
  color: #fff;
  text-align: center;
  font-family: "Fira Sans";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 900;
}

.generic-xl-bold-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 2.8vw;
  font-style: normal;
  font-weight: 600;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.generic-small-bold-italic-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: italic;
  font-weight: 600;
}

.generic-button-container {
  height: 100%;
  width: 7%;
  border-radius: 50px;
  border: white 1px solid;
  padding: 1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Audio progress bar */

.audio-progress-bar-container {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=range].audio-progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;     /*nécessaire pour Chrome */
  padding: 0;                   /* nécessaire pour IE */
  font: inherit;                /* même rendu suivant font document */
  outline: none;
  color: #000000;               /* sert pour couleur de référence, via currentColor, pour le curseur */
  background: #FFFFFF;             /* sert pour couleur de fond de la zone de déplacement */
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;       /* même modèle de boîte pour tous */
  transition: opacity .2s;
  cursor: pointer;
  height: 2px;
  width: 100%;
  border-radius: 10px;
}


/* Chrome, Safari, Edge, Opera */
/* la zone de déplacement */
input[type=range].audio-progress-bar::-webkit-slider-runnable-track {
  height: 100%;
  border: none;
  border-radius: 20%;
  background-color: transparent;  /* supprimé définie sur l'input */
  border: var(--black) 1vw solid;
}

/* le curseur */
input[type=range].audio-progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;       /* également nécessaire sur le curseur */
  height: 12px;                /* s'adapte à la hauteur de l'input */
  width: 12px;
  border: none;
  border-radius: 50%;               /* pris en compte sur Webkit et Edge */
  background: white;       /* pris en compte sur Webkit only */
}


/* Firefox */
/* la zone de déplacement */
input[type=range].audio-progress-bar::-moz-range-track {
  height: 100%;
  border: none;
  border-radius: 20%;
  background-color: transparent;  /* supprimé définie sur l'input */
}

/* le curseur */
input[type=range].audio-progress-bar::-moz-range-thumb {
  -moz-appearance: none;          /* également nécessaire sur le curseur */
  height: 12px;                /* s'adapte à la hauteur de l'input */
  width: 12px;
  border: none;
  border-radius: 50%;               /* pris en compte sur Webkit et Edge */
  background: white;       /* pris en compte sur Webkit only */
}
/* */

/* Works on Firefox */
* {
  scrollbar-color: var(--black) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px  !important;
  background-color: transparent !important;
}

*::-webkit-scrollbar-track {
  background-color: transparent !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--black) !important;
  border-radius: 20px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--dark-black) !important;
  cursor: pointer !important;
}

/* Tooltip container */

.tooltip-title-container {
  border-radius: 10%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.tooltip-title-container:hover {
  cursor: pointer;
}

.tootltip-title-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.tooltip-title {
  display: flex;
  flex-direction: row;
  width: 4vw;
  align-items: center;
  background-color: var(--dark-grey);
  padding: 5%;
  border-radius: 10px;
  justify-content: space-around;
  margin-top: 0;
  margin-left: 0;
  padding: 0.4vw;
  gap: 0.5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  border-radius: 10px;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  border-radius: 5px;
  width: 2rem;
  padding: 1%;
  text-align: end;
}

input[type="number"]::-webkit-textfield-decoration-container {
  appearance: none;
  color: #fff;
  font-family: "Fira Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

input[type="checkbox"] {
  appearance: none;
  background-color: var(--dark-grey);
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 5px var(--dark-grey);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.8rem;
  height: 1rem;
  border-radius: 5px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: rgb(26, 25, 30);
}

input[type="checkbox"]:checked::before {
  clip-path: polygon(14% 55%, 0 55%, 50% 85%, 85% 30%, 95% 10%, 43% 62%);
  font-size: 0.8rem;
  background-color: white;
  transform: scale(1);
}

.checkbox {
  background-color: var(--dark-grey);
  width: 1vw;
}

.checkbox-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.checkbox-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.5rem;
  margin: 2%;
}

.download-tooltip-export-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.5rem;
  padding-bottom: 4%;
}

.download-tooltip-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.6vw;
}

.download-tooltip-title-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 4%;
  padding: 4%;
  padding-bottom: 0%;
}

.download-tooltip-title {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
}

.download-tooltip-export-background {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.5rem;
  padding: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 20px;
  background-color: var(--dark-grey);
  cursor: pointer;
}

.download-tooltip-export-background:hover {
  background-color:rgb(26, 25, 30);
}

.download-tooltip-export {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.download-tooltip-main-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0.5vw;
}

.download-tooltip-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.download-tooltip-footer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

/* */