.sep-tool-footer-main-container {
    width: 100%;
    height: 13%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0.4vw;
    background-color: var(--dark-grey);
    border-radius: 0.4vw;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.source-sep-source-sep-right-split-border-container {
    /* width: 43%; */
    height: 100%;
    width: 18%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.source-sep-source-sep-right-split-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #1f1e22;
    border-radius: 10px;
    justify-content: space-around;
    align-items: center;
    padding: 1%;
}

.delete-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    text-align: center;
    width: 100%;
}

.delete-modal-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 30%;
    width: 100%;
}