.split-button-container {
    background-color: var(--purple);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    height: 100%;
    width: 100%;
    gap: 5%;
    padding: 2%;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.split-button-container:hover {
    background-color: var(--darkPurple);
}

.split-button-progress {
    background-color: var(--purple);
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50px;
    z-index: 2;
}