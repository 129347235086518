.source-sep-home-page {
  width: 60%;
  height: 92%;
  display: flex;
  margin: 1.5%;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient(180deg, #28272c 0%, rgba(26, 25, 30, 0.72) 100%);
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  padding: 2%;
  z-index: 1;
  position: relative;
}

.source-sep-home-page-container {
  height: 100%;
  width: 100%;
}

.home-page-title {
  font-size: 4.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}

.home-page-subtitle {
  font-size: 1.3vw;
  font-style: italic;
  font-weight: 400;
}

.home-page-description-container {
  display: flex;
  height: 30%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-block: 3%;
  gap: 3%;
}

.description-container {
  background-color: #1A191E;
  border-radius: 10px;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
  width: 30%;
  padding-inline: 1.5%;
  padding-block: 2.5%;
  gap: 5%;
  display: grid;
  font-size: 0.8vw;
}

.description-title {
  font-weight: 600;
}

.home-page-button-container {
  width: 35%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  gap: 5%;
}