.not-found-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.not-found-page {
  border-radius: 15px;
  background: linear-gradient(180deg, #28272c 0%, rgba(26, 25, 30, 0.72) 100%);
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 70%;
  flex-direction: column;
  gap: 4%;
}

.not-found-icon {
    width: 15%;
    height: auto;
}