.text-input-field-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12vw;
    background-color: var(--black);
    padding: 0.4vw;
    font-size: 0.7vw;
    border-radius: 0.4vw;
    color: white;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.text-input-field-box:hover {
    background-color: var(--dark-grey);
}

.text-field-title {
    font-weight: 200;
    text-align: left;
    font-size: 0.6vw;
    margin-left: 0;
}
