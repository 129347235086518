.sep-tool-main-model-selector-container-border-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0.4vw;
    background-color: var(--special-black);
    opacity: 0.86;
    padding: 1vw;
}

.sep-tool-model-selector-container-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5vw;
    border: dashed 1px white;
    border-radius: 0.4vw;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 0.4vw;
    opacity: 1;
}

.sep-tool-model-selector-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1vw;
}

.sep-tool-model-selector-generating-in-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}