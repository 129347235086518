.song-column-container {
  height: 6vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.song-infos-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.song-column-start {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2%;
  overflow-wrap: anywhere;
}

.song-column-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.song-column-sep-model {
  background-color: #3f4043;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 60%;
  width: 80%;
  gap: 10px;
  cursor: pointer;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.song-column-download {
  background-color: #3f4043;
  border-radius: 50px;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: 65%;
  padding-left: 5%;
  cursor: pointer;
}

.song-column-download:hover {
  background-color: #1A191E;
}

.song-column-download-button {
  border-radius: 50px;
  padding: 5%;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
}

.song-column-button-container {
  background-color: #3f4043;
  border-radius: 50px;
  display: flex;
  height: 3vh;
  width: 1.7vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}
