.main-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 2.5%;
  padding-block: 0.5%;
}

.main-page-toggle {
  height: 11.5%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.toggle-left-container {
  border-radius: 10px 0 0px 10px;
  background-color: #28272c;
  width: 100%;
  height: 80%;
  padding: 0.5% 0.3% 0.5% 0.8%;
}

.toggle-right-container {
  border-radius: 0px 10px 10px 0;
  background-color: #28272c;
  width: 50%;
  height: 80%;
  padding: 0.5% 0.8% 0.5% 0.3%;
}

.toggle-button {
  background-color: #1f1e22;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1%;
}

.toggle-button-selected {
  transition: ease-in-out 0.3s;
  background-color: #2a2930;
}
