.sep-tool-output-track-border-container {
    width: 100%;
    height: 21%;
    box-sizing: border-box;
    display: flex;
    object-fit: cover;
    position: relative;
}

.sep-tool-output-track-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.4vw;
    gap: 0.6vw;
}

.sep-tool-output-track-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7%;
    height: 100%;
    padding-top: 0.4vw;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.4vw;
}

.sep-tool-output-track-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 93%;
    height: 100%;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.4vw;
}

.sep-tool-output-track-left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5vw;
    width: 10%;
}

.sep-tool-output-track-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
}

.bassnet-audio-track-square-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    padding: 0.4vw;
    width: 1.7vw;
    font-size: 0.7vw;
    border-radius: 0.5vw;
    color: white;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.bassnet-audio-track-square-button:hover {
    background-color: var(--light-grey);
    cursor: pointer;
}

.sep-tool-output-track-right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 79.5vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0.4vw 0vw 0.4vw 0.6vw;
}

.source-sep-download-icon {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.source-sep-audio-track-volume-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 0.5vw;
    gap: 0.3vw;
    width: 85%;
}

.source-sep-audio-track-volume-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.source-sep-mute-audio-track-container {
    width: 100%;
    height: 9.5vh;
    background: rgba(40, 39, 44, 0.70);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    border-radius: 10px;
    gap: 0.5vw;
}

.source-sep-no-audio-tracks-text {
    display: flex;
    padding: 0.35vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    border-radius: 1vw;
    background-color: var(--black);
    font-size: 0.6vw;
    font-weight: 800;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}