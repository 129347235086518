
.source-sep-library-left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20%;
    gap: 10px;
}

.source-sep-library-left-main {
    background: #1F1E22;
    border-radius: 10px;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    gap: 2%;
    justify-content: flex-start;
    flex-direction: column;
}

.source-sep-library-left-footer-container {
    background: #1F1E22;
    border-radius: 10px;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 12%;
    padding: 2%;
}

.source-sep-library-left-footer-container:hover {
    background-color: #3F4043;
}

.source-sep-library-left-footer {
    border: dotted 3px white;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.source-sep-library-left-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 9%;
    width: 100%;
}

.source-sep-library-left-header-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 35%;
    gap: 10px;
    margin-inline: 1.5%;
    height: 80%;
    cursor:pointer;
}

.source-sep-library-left-searchbar {
    background-color: #3F4043;
    border-radius: 10px;
    display: flex;
    height: 6%;
    width: 96%;
    align-items: center;
    justify-content: flex-start;
    gap: 2%;
    padding-left: 4%;
}

.source-sep-library-searchbar-input {
    background-color: #3F4043;
    border: none;
    outline: none;
    color: white;
    width: 85%;
    height: 95%;
}

.source-sep-library-searchbar-input::placeholder {
    color: #707070;
}

.source-sep-library-left-songs {
    display: flex;
    flex-direction: column;
    width: 96%;
    height: 100%;
    overflow: auto;
    gap: 5px;
}

.source-sep-library-main-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    color: white;
    height: 7%;
}

.source-sep-library-main-arrows {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.library-left-folder-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 5px;
}

.source-sep-library-icon-button {
    background-color: #1A191E;
    border-radius: 10px;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.source-sep-library-icon-button:hover {
    background-color: #3F4043;
}
