.source-sep-library-container {
    height: 88.5%;
    width: 100%;
    background: #28272C;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 0.5%;
    gap: 0.5%;
}

.source-sep-icon-title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    background: #2A2930;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    height: 100%;
    padding-block: 2%;
    padding-inline: 3%;
    width: 100%;
}

.source-sep-icon-title-main-container {
    display: flex;
    flex-direction: row;
    gap: 3%;
    width: 100%;
}
