.footer-main-container {
  height: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: white;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--dark-grey);
  font-weight: 200;
  font-size: 0.7vw;
  justify-content: center;
}

.sony-footer {
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 700;
  box-sizing: border-box;
}

a {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 950px) {
  .sony-footer {
    padding: 0rem 2rem;
  }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
  .footer-main-container {
    height: 12vh;
  }
}
