.drag-and-drop-container-border-container {
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.drag-and-drop-container-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--special-black);
    border-radius: 0.5vw;
    position: relative;
    flex-direction: column;
    padding: 0.5rem;
    box-sizing: border-box;
}

.drag-and-drop-container-main-container:hover {
    background-color: var(--light-grey);
}

.drag-and-drop-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: row;
    border: dashed 1px white;
    border-radius: 0.4vw;
    font-size: 0.6vw;
    font-weight: 900;
    box-sizing: border-box;
    padding: 1rem;
    text-align: center;
    cursor: copy;
}

.dd-bold-text-container {
    font-weight: 900;
    font-size: 0.7vw;
}

.dd-underlined-text-container {
    font-weight: 400;
    opacity: 0.8;
    text-decoration: underline;
}

.drag-and-drop-container:hover {
    background-color: var(--light-grey);
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .drag-and-drop-container-border-container {
        width: 50%;
        height: 50%;
        box-sizing: border-box;
        padding: 0.3rem;
    }
}
