.song-details-container {
    height: 98%;
    width: 100%;
    border-radius: 10px;
    background-color: #1A191E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.5%;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
}

.song-details-header {
    display: flex;
    height: 15%;
    width: 100%;
    padding: 1%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.song-details-main {
    height: 90%;
    width: 100%;
    padding-inline: 3%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.song-details-header-left {
    display: flex;
    height: 100%;
    width: 30%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.song-details-header-right {
    justify-content: center;
    align-items: center;
    width: 3%;
    height: 100%;
    display: flex;
    cursor: pointer;
}

.song-details-header-left-model-container {
    width: 33%;
    height: 100%;
    background-color: var(--purple);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.song-details-header-button {
    background-color: #28272C;
    border-radius: 50px;
    width: 9%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
}

.song-details-header-button:hover {
    background-color: #3F4043;
}

.separation-line-container {
    display: flex;
    height: 20%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.separation-line {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-block: 1%;
}

.separation-line-infos {
    display: flex;
    width: 35%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.separation-line-name-container {
    border-radius: 10px;
    background-color: #2A2930;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    width: 55%;
    height: 100%;
    cursor: pointer;
}

.separation-line-download {
    width: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.separation-line-download-button {
    background-color: #3F4043;
    border-radius: 50px;
    padding: 10%;
}

.no-separation-container {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 30%;
    align-items: center;
    justify-content: center;
    gap: 10%;
}