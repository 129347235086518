.source-sep-library-right-container {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  padding-bottom: 0.5%;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.source-sep-library-right-header {
  background-color: #3f4043;
  width: 100%;
  height: 8%;
  border-radius: 10px;
  margin-bottom: 1%;
}

.source-sep-library-right-columns-container {
  background-color: #2a2930;
  display: flex;
  flex-direction: column;
  width: 99%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.source-sep-library-right-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
  height: 7%;
  border-radius: 10px;
}

.source-sep-library-right-main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2%;
}

.source-sep-library-column-arrow-dropdown {
  width: 2.5%;
  display: flex;
  padding: 0.8%;
  align-items: center;
  justify-content: center;
  margin: 0.5%;
  background-color: #28272c;
  border-radius: 50px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.source-sep-library-right-no-track {
  background-color: #1a191e;
  border-radius: 15px;
  padding-block: 0.5%;
  padding-inline: 1%;
  font-weight: 600;
  color: white;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.source-sep-library-right-song-infos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: scroll;
}