.header-main-container {
  height: 14%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 40px;
  box-sizing: border-box;
  flex-direction: column;
  color: white;
  font-weight: 200;
  position: relative;
  z-index: 2;
}

.header-buttons-container {
  display: flex;
  flex-direction: row;
  height: 50%;
  width: 20%;
  align-items: center;
  justify-content: space-around;
}

.header-sony-container {
  background-color: black;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 8rem;
  box-sizing: border-box;
}

.header-source-sep-container {
  width: 100%;
  height: 60%;
  background-color: var(--dark-grey);
  border-radius: 10px;
  box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 1rem 0px 0px;
  position: relative;
}

.header-logo-and-name-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 1rem;
}

.header-logo-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-logo-background {
  height: 100%;
  width: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--purple);
  border-radius: 10px;
}

.header-help-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-right: 0.3rem;
}

.helper-text-main-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  font-size: 18px;
  gap: 1.4rem;
}

.helper-text-up-container {
  width: 100%;
  height: 20%;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Fira Sans";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 300;
}

.helper-text-down-container {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.helper-text-down-box-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.helper-text-keyboard-container {
  width: 100%;
  height: auto;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.helper-info-text {
  display: flex;
  flex-direction: row;
  height: 10%;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Fira Sans";
  font-size: 0.65vw;
  font-style: italic;
  font-weight: 300;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2%;
}

.helper-important-icon {
    border-radius: 50px;
    border: 1px solid;
    padding-inline: 2%;
    padding-block: 1.2%;
    width: 6%;
    margin-top: 1%;
}